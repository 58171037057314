import { ORDER_DIRECTION, SELECT_PARAM_STAR } from "types";

export interface ParamsUserRequest {
  limit: number;
  page: number;
  orderBy?: string;
  orderDirection?: string;
  search: string;
}

export interface UserDetail {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  avatar: string;
  status: string;
  role: RoleDetail;
  createdAt: string;
}

export interface CreateUserRequest {
  email: string;
  password: string;
  confirmPassword: string;
  firstName: string;
  lastName: string;
  roleId: number;
}

export interface UpdateUserRequest {
  firstName: string;
  lastName: string;
  roleId: number;
}

export const defaultEmptyUpdateUserRequest: UpdateUserRequest = {
  firstName: "",
  lastName: "",
  roleId: 0,
};

export const defaultEmptyCreateUserRequest: CreateUserRequest = {
  email: "",
  password: "",
  confirmPassword: "",
  firstName: "",
  lastName: "",
  roleId: 0,
};

export interface RoleDetail {
  id: number;
  name: string;
  value?: string;
  label?: string;
}

export interface FormDialogProps {
  roles: RoleDetail[];
  handleUpdateList: (dataUser: UserDetail) => void;
}

export interface EditDialogProps {
  roles: RoleDetail[];
  handleUpdateList: (dataUser: UserDetail) => void;
  dataItem: UserDetail;
}

export const LIST_ODER_BY_USER: SELECT_PARAM_STAR[] = [
  {
    label: "No",
    value: "",
  },
  {
    label: "Email",
    value: "email",
  },
  {
    label: "First Name",
    value: "firstName",
  },
  {
    label: "Last Name",
    value: "lastName",
  },
  {
    label: "Role",
    value: "role",
  },
  {
    label: "Create At",
    value: "createdAt",
  },
];
export const LIST_ORDER_DIRECTION_USER: SELECT_PARAM_STAR[] = [
  {
    label: ORDER_DIRECTION.ASC,
    value: ORDER_DIRECTION.ASC,
  },
  {
    label: ORDER_DIRECTION.DESC,
    value: ORDER_DIRECTION.DESC,
  },
];
