import BackdropCustomize from "components/BackdropCustomize";
import ButtonDefault from "components/Button/ButtonDefault";
import DialogCard from "components/Dialog/DialogCard";
import InputDefault from "components/Input/InputDefault";
import SelectDefault from "components/Select/SelectDefault";
import Typography from "components/Typography";
import { useBoolean } from "helpers/hooks";
import { useEffect, useRef, useState } from "react";
import { ReactComponent as IconEdit } from "assets/images/icons/edit.svg";
import {
  defaultEmptyUpdateUserRequest,
  EditDialogProps,
  RoleDetail,
  UpdateUserRequest,
  UserDetail,
} from "../types";
import { STATUS_RESPONSE_CODE } from "types";
import { updateUserMiddleware } from "../services/api";

const EditDialog = (props: EditDialogProps): JSX.Element => {
  const { roles, handleUpdateList, dataItem } = props;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const ref = useRef<any>();
  const isLoading = useBoolean();
  const [updateUser, setUpdateUser] = useState<UpdateUserRequest>(
    defaultEmptyUpdateUserRequest
  );
  const [selectRole, setSelectRole] = useState<RoleDetail | null>(null);

  useEffect(() => {
    setUpdateUser({
      ...updateUser,
      lastName: dataItem.lastName,
      firstName: dataItem.firstName,
    });
    if (dataItem.role) {
      setSelectRole({
        ...dataItem.role,
        label: dataItem.role.name,
        value: dataItem.role.name,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataItem]);
  const handleChangeInput =
    (key: "lastName" | "firstName") =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setUpdateUser({
        ...updateUser,
        [key]: event.target.value,
      });
    };
  const handleChangeSelect = (selected: RoleDetail) => {
    setSelectRole(selected);
  };

  const onKeyPress = (event: React.KeyboardEvent<HTMLDivElement>): void => {
    if (event.key === "Enter") {
      event.preventDefault();
      if (isDisabledButton()) {
        return;
      }
      onSubmitAdd();
    }
  };

  const isDisabledButton = () => {
    if (!updateUser.firstName || !updateUser.lastName) {
      return true;
    }
    if (!selectRole?.id) {
      return true;
    }
    return false;
  };

  const onSubmitAdd = () => {
    if (!dataItem.id) {
      return;
    }
    const dataRequest: UpdateUserRequest = {
      ...updateUser,
      roleId: selectRole ? selectRole.id : 0,
    };
    isLoading.setValue(true);
    updateUserMiddleware(
      dataItem.id,
      dataRequest,
      (status: STATUS_RESPONSE_CODE, dataRes?: UserDetail) => {
        if (status === STATUS_RESPONSE_CODE.SUCCESS && dataRes) {
          handleCLoseDialog();
          handleUpdateList(dataRes);
        }
        isLoading.setValue(false);
      }
    );
  };

  const handleCLoseDialog = () => {
    ref.current.close();
    setUpdateUser({
      ...updateUser,
      lastName: dataItem.lastName,
      firstName: dataItem.firstName,
    });
    if (dataItem.role) {
      setSelectRole({
        ...dataItem.role,
        label: dataItem.role.name,
        value: dataItem.role.name,
      });
    }
  };

  return (
    <DialogCard
      popupRef={ref}
      handleCLoseDialog={handleCLoseDialog}
      title="Update user"
      childrenAction={
        <div className="p-2 rounded-full hover:bg-primary-04-custom cursor-pointer">
          <IconEdit className={`w-18-custom h-18-custom icon-edit-color`} />
        </div>
      }
    >
      <InputDefault
        label="First name"
        required
        rootClass="mb-6"
        value={updateUser.firstName}
        onChange={handleChangeInput("firstName")}
        onKeyPress={onKeyPress}
      />
      <InputDefault
        label="Last name"
        required
        rootClass="mb-6"
        value={updateUser.lastName}
        onChange={handleChangeInput("lastName")}
        onKeyPress={onKeyPress}
      />

      <Typography
        fontWeight="font-semibold"
        textColor="text-gray-custom"
        textClass="text-xs mb-1"
      >
        Role*
      </Typography>
      <SelectDefault
        options={roles}
        selectedOption={selectRole}
        handleChange={handleChangeSelect}
        filed="name"
        rootClasses="mb-14"
      />
      <ButtonDefault
        widthButton="w-140-custom"
        disabled={isDisabledButton()}
        onClick={onSubmitAdd}
      >
        Update
      </ButtonDefault>
      {isLoading.value ? <BackdropCustomize /> : null}
    </DialogCard>
  );
};

export default EditDialog;
