import React from "react";
import { RoleDetail, UserDetail } from "../types";
import { Header } from "components/Table/types";
import EditDialog from "../organisms/EditDialog";
import { formatDate } from "helpers/util";
import DefaultButtonIcon from "components/ButtonIcon/DefaultButtonIcon";

export function dataHeaderUser(
  roles: RoleDetail[],
  handleUpdateList: (dataUser: UserDetail) => void,
  handleOpenUpdateList: (
    key: "delete" | "edit",
    dataRes: UserDetail
  ) => () => void
): Header[] {
  const headers: Header[] = [
    {
      title: "Email",
      field: "email",
      sort: true,
      styleHeader: {
        paddingRight: 10,
        width: 300,
        minWidth: 300,
      },
      styleBody: {
        paddingRight: 10,
        width: 300,
        minWidth: 300,
      },
      renderBody: (value: UserDetail) => (
        <p className="text-primary-custom cursor-pointer">{value.email}</p>
      ),
    },
    {
      title: "First Name",
      field: "firstName",
      sort: true,
      styleHeader: {
        paddingRight: 10,
        paddingLeft: 10,
        width: 150,
        minWidth: 150,
      },
      styleBody: {
        paddingRight: 10,
        paddingLeft: 10,
        width: 150,
        minWidth: 150,
      },
    },
    {
      title: "Last Name",
      field: "lastName",
      sort: true,
      styleHeader: {
        paddingRight: 10,
        paddingLeft: 10,
        width: 150,
        minWidth: 150,
      },
      styleBody: {
        paddingRight: 10,
        paddingLeft: 10,
        width: 150,
        minWidth: 150,
      },
    },
    {
      title: "Role",
      field: "role",
      sort: true,
      styleHeader: {
        paddingRight: 10,
        paddingLeft: 10,
        width: 100,
        minWidth: 100,
        maxWidth: 100,
      },
      styleBody: {
        paddingRight: 10,
        paddingLeft: 10,
        width: 100,
        minWidth: 100,
        maxWidth: 100,
      },
      renderBody: (value: UserDetail) => (
        <p>{value.role ? value.role.name : ""}</p>
      ),
    },
    {
      title: "Created At",
      field: "createdAt",
      styleHeader: {
        paddingRight: 10,
        paddingLeft: 10,
        width: 160,
        minWidth: 160,
        maxWidth: 160,
      },
      styleBody: {
        paddingRight: 10,
        paddingLeft: 10,
        width: 160,
        minWidth: 160,
        maxWidth: 160,
      },
      sort: true,
      renderBody: (value: UserDetail) => (
        <p>{value.createdAt ? formatDate(value.createdAt) : ""}</p>
      ),
    },
    {
      title: "Action",
      field: "action",
      styleSort: {
        justifyContent: "flex-end",
      },
      styleHeader: {
        paddingLeft: 10,
        width: "100%",
        minWidth: 150,
      },
      styleBody: {
        paddingLeft: 10,
        width: "100%",
        minWidth: 150,
      },
      renderBody: (value: UserDetail) => (
        <div className="flex items-center justify-end">
          <EditDialog
            roles={roles}
            handleUpdateList={handleUpdateList}
            dataItem={value}
          />
          <div className="ml-2">
            <DefaultButtonIcon
              onClick={handleOpenUpdateList("delete", value)}
              disable={false}
              field="delete"
              titleTooltip="Destroy"
              keyButtonIcon={Math.random().toString(36)}
            />
          </div>
        </div>
      ),
    },
  ];

  return headers;
}
