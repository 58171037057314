export const PATH = {
  home: "/home",
  aboutUs: "/about-us",
  login: "/login",
  forgotPassword: "/forgot-password",
  resetPassword: "/reset-password",
  cmsUsers: "/cms-users",
  mobileUsers: "/mobile-users",
  stars: "/stars",
  keywords: "/keywords",
  legalPage: "/legal-page",
  referralCode: "/referral-code",
  notifications: "/notifications",
  fee: "/fee",
  settings: "/settings",
  events: "/events",
  reports: "/reports",
  treasure: "/treasure",
  flag: "/flag",
  advertisement: "/advertisement",
  gif: "/gif",
  manageVersions: "/manage-versions",
};

export const EMPTY_ADDRESS = "0x78ab6140f3944e8190c596bf2c3a23a1";
export const ZERO_ADDRESS = "0x0000000000000000000000000000000000000000";
