import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { PaginatedItemsProps } from "./types";

const PaginatedItems = (props: PaginatedItemsProps): JSX.Element => {
  const { limit, page, countItems, handleChangePage } = props;
  const pageCount = Math.ceil(countItems / limit);
  const [currentPage, setCurrentPage] = useState(page);

  useEffect(() => {
    if (!currentPage) {
      return;
    }
    if (currentPage <= pageCount) {
      handleChangePage(currentPage);
    } else {
      setCurrentPage(pageCount);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handlePageClick = (event: any) => {
    // handleChangePage(Number(event.selZected) + 1);
    setCurrentPage(Number(event.selected) + 1);
  };
  const handleOnChange = (event: any) => {
    setCurrentPage(Number(event.target.value));
  };
  if (pageCount < 2) {
    return <div />;
  }
  return (
    <div className="flex items-center table-pagination">
      <input
        type="text"
        className="block p-2 text-black bg-gray-50 rounded-lg border border-gray-300 sm:text-xs focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        style={{ width: 100 }}
        value={currentPage}
        onChange={handleOnChange}
      />
      <ReactPaginate
        nextLabel=">"
        onPageChange={handlePageClick}
        pageCount={pageCount}
        forcePage={currentPage - 1}
        previousLabel="<"
        pageClassName="page-item"
        previousClassName="page-item-next"
        nextClassName="page-item-next"
        breakLabel="..."
        containerClassName="pagination"
        activeClassName="active"
      />
    </div>
  );
};
export default PaginatedItems;
