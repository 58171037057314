import BackdropCustomize from "components/BackdropCustomize";
import ButtonDefault from "components/Button/ButtonDefault";
import DialogCard from "components/Dialog/DialogCard";
import IconInputPassword from "components/Input/IconInputPassword";
import InputDefault from "components/Input/InputDefault";
import { STATUS_INPUT } from "components/Input/types";
import SelectDefault from "components/Select/SelectDefault";
import Typography from "components/Typography";
import { useBoolean } from "helpers/hooks";
import { isValidEmail } from "helpers/util";
import { useRef, useState } from "react";
import { STATUS_RESPONSE_CODE } from "types";
import { addUserMiddleware } from "../services/api";
import {
  CreateUserRequest,
  defaultEmptyCreateUserRequest,
  FormDialogProps,
  RoleDetail,
  UserDetail,
} from "../types";

const FormDialog = (props: FormDialogProps): JSX.Element => {
  const { roles, handleUpdateList } = props;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const ref = useRef<any>();

  const isLoading = useBoolean();
  const [createUser, setCreateUser] = useState<CreateUserRequest>(
    defaultEmptyCreateUserRequest
  );
  const changeTypePasswordInput = useBoolean();
  const [statusEmailInput, setStatusEmailInput] = useState<STATUS_INPUT>(
    STATUS_INPUT.DEFAULT
  );
  const [selectRole, setSelectRole] = useState<RoleDetail | null>(null);
  const handleChangeIconPassword = () => {
    changeTypePasswordInput.setValue(!changeTypePasswordInput.value);
  };

  const handleChangeInput =
    (
      key: "email" | "password" | "confirmPassword" | "lastName" | "firstName"
    ) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (key === "email") {
        let newStatus = STATUS_INPUT.DEFAULT;
        if (event.target.value) {
          newStatus = isValidEmail(event.target.value)
            ? STATUS_INPUT.VALID
            : STATUS_INPUT.ERROR;
        }
        setStatusEmailInput(newStatus);
      }
      setCreateUser({
        ...createUser,
        [key]: event.target.value,
      });
    };
  const handleChangeSelect = (selected: RoleDetail) => {
    setSelectRole(selected);
  };

  const onKeyPress = (event: React.KeyboardEvent<HTMLDivElement>): void => {
    if (event.key === "Enter") {
      event.preventDefault();
      if (isDisabledButton()) {
        return;
      }
      onSubmitAdd();
    }
  };

  const isDisabledButton = () => {
    if (
      !createUser.firstName ||
      !createUser.lastName ||
      createUser.confirmPassword !== createUser.password ||
      !createUser.password
    ) {
      return true;
    }
    if (!selectRole?.id) {
      return true;
    }
    return !isValidEmail(createUser.email);
  };

  const onSubmitAdd = () => {
    const dataRequest: CreateUserRequest = {
      ...createUser,
      roleId: selectRole ? selectRole.id : 0,
    };
    isLoading.setValue(true);
    addUserMiddleware(
      dataRequest,
      (status: STATUS_RESPONSE_CODE, dataRes?: UserDetail) => {
        if (status === STATUS_RESPONSE_CODE.SUCCESS && dataRes) {
          handleCLoseDialog();
          handleUpdateList(dataRes);
        }
        isLoading.setValue(false);
      }
    );
  };

  const handleCLoseDialog = () => {
    ref.current.close();
    setCreateUser(defaultEmptyCreateUserRequest);
    setSelectRole(null);
  };

  return (
    <DialogCard
      popupRef={ref}
      handleCLoseDialog={handleCLoseDialog}
      title="Add user"
      childrenAction={
        <ButtonDefault widthButton="w-140-custom">Add user</ButtonDefault>
      }
    >
      <InputDefault
        label="Email"
        required
        rootClass="mb-6"
        value={createUser.email}
        onChange={handleChangeInput("email")}
        status={statusEmailInput}
        onKeyPress={onKeyPress}
      />
      <InputDefault
        label="First name"
        required
        rootClass="mb-6"
        value={createUser.firstName}
        onChange={handleChangeInput("firstName")}
        onKeyPress={onKeyPress}
      />
      <InputDefault
        label="Last name"
        required
        rootClass="mb-6"
        value={createUser.lastName}
        onChange={handleChangeInput("lastName")}
        onKeyPress={onKeyPress}
      />
      <InputDefault
        label="Password"
        required
        rootClass="mb-6"
        value={createUser.password}
        type={changeTypePasswordInput.value ? "text" : "password"}
        childrenIcon={
          <IconInputPassword
            isShowPassword={changeTypePasswordInput.value}
            handleChangeIconPassword={handleChangeIconPassword}
          />
        }
        onChange={handleChangeInput("password")}
        onKeyPress={onKeyPress}
      />
      <InputDefault
        label="Confirm password"
        required
        rootClass="mb-6"
        value={createUser.confirmPassword}
        type={changeTypePasswordInput.value ? "text" : "password"}
        childrenIcon={
          <IconInputPassword
            isShowPassword={changeTypePasswordInput.value}
            handleChangeIconPassword={handleChangeIconPassword}
          />
        }
        onChange={handleChangeInput("confirmPassword")}
        onKeyPress={onKeyPress}
      />
      <Typography
        fontWeight="font-semibold"
        textColor="text-gray-custom"
        textClass="text-xs mb-1"
      >
        Role*
      </Typography>
      <SelectDefault
        options={roles}
        selectedOption={selectRole}
        handleChange={handleChangeSelect}
        filed="name"
        rootClasses="mb-14"
      />
      <ButtonDefault
        widthButton="w-140-custom"
        disabled={isDisabledButton()}
        onClick={onSubmitAdd}
      >
        Add
      </ButtonDefault>
      {isLoading.value ? <BackdropCustomize /> : null}
    </DialogCard>
  );
};

export default FormDialog;
