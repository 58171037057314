/* eslint-disable @typescript-eslint/no-explicit-any */
import TitleStarCard from "../molecules/TitleStarCard";
import { ImagePreviewCardProps } from "../types";
import IconAddAvatar from "assets/images/icons/add-avatar.svg";
import BioCard from "../molecules/BioCard";
import React from "react";
import { getDefaultImageStar } from "helpers/util";
import { useBoolean } from "helpers/hooks";

import ButtonDefault from "components/Button/ButtonDefault";
import { Form3dStar } from "./Form3dStar";

const ImagePreviewCard = (props: ImagePreviewCardProps): JSX.Element => {
  const {
    starDetail,
    fileSelectedHandlerImagePreview,
    fileSelectedHandlerImage3d,
    keyInputFileImagePreview,
    keyInputFileImage3d,
    dataItem,
    updateStarDetailWhenUpdateBIO,
  } = props;

  const openViewDetail = useBoolean();

  const [refetch, setRefetch] = React.useState(0);

  const getImage = () => {
    if (starDetail.imgUrlPreview) {
      return starDetail.imgUrlPreview;
    }
    return getDefaultImageStar(starDetail.model);
  };

  const getImage3d = () => {
    if (starDetail.img3dUrl) {
      console.log("change", starDetail.img3dUrl);
      return starDetail.img3dUrl;
    }
    return getDefaultImageStar(starDetail.model);
  };

  const onCloseDialog = () => {
    openViewDetail.setValue(false);
  };

  return (
    <div>
      <div className="flex grid-image-preview">
        <div className="relative">
          <img
            src={getImage()}
            className="w-112-custom h-120-custom rounded-full object-cover	"
          />
          {/* {!dataItem.nftId ? ( */}
          <React.Fragment>
            <input
              id="container-files-image-preview"
              onChange={fileSelectedHandlerImagePreview}
              accept={"image/*"}
              type="file"
              style={{ display: "none" }}
              key={keyInputFileImagePreview || ""}
            />
            <label htmlFor="container-files-image-preview">
              <div>
                <img
                  src={IconAddAvatar}
                  alt="icon"
                  className="absolute bottom-12 right-1 cursor-pointer"
                />
              </div>
            </label>
          </React.Fragment>
          {/* ) : null} */}
          <div className="flex flex-col items-center justify-center mt-3">
            <p className="text-primary-custom font-semibold text-xs mb-1">
              Thumbnail image
            </p>
            <p className="text-primary-custom font-semibold text-xs">
              ({`900 x 900`})
            </p>
          </div>
        </div>

        <div className="flex flex-col justify-center ml-8">
          <TitleStarCard
            label="Mag:"
            message={dataItem.info?.mag ?? "No data"}
            rootClassName="mb-3"
          />
          <TitleStarCard
            label="Visibility:"
            message={dataItem.info?.visibility ?? "No data"}
            isHtml
            rootClassName="mb-3"
          />
          <TitleStarCard
            label="Ra/Dec:"
            message={dataItem.info?.raDec ?? "No data"}
            isHtml
            rootClassName="mb-3"
          />
          <TitleStarCard
            label="Az/Alt:"
            message={dataItem.info?.azAlt ?? "No data"}
            isHtml
          />
        </div>

        <div className="flex justify-center mb-3 ml-20">
          <div className="relative w-250-custom">
            <div
              style={{
                backgroundImage: `url(${getImage3d()})`,
                backgroundColor: !starDetail.img3dUrl ? "#e0e0e0" : "",
                backgroundSize: "cover",
              }}
              className="h-115-custom rounded-lg bg-no-repeat bg-center"
            />
            {/* {!dataItem.nftId ? ( */}
            <React.Fragment>
              <input
                id="container-files-image-3d"
                onChange={fileSelectedHandlerImage3d}
                accept={"image/*"}
                type="file"
                style={{ display: "none" }}
                key={keyInputFileImage3d || ""}
              />
              <label htmlFor="container-files-image-3d">
                <div>
                  <img
                    src={IconAddAvatar}
                    alt="icon"
                    className="absolute bottom-12 right-2 cursor-pointer"
                  />
                </div>
              </label>
            </React.Fragment>
            <div className="flex flex-col items-center justify-center mt-3">
              <p className="text-primary-custom font-semibold text-xs mb-1">
                3D image
              </p>
            </div>
            {/* ) : null} */}
          </div>
        </div>
        <div className="flex-col justify-center mb-3 ml-5">
          <ButtonDefault
            widthButton="w-140-custom"
            onClick={() => openViewDetail.setValue(true)}
          >
            View 3D
          </ButtonDefault>
        </div>
      </div>
      <BioCard
        modelData={
          starDetail.info && starDetail.info.modelData
            ? starDetail.info.modelData
            : ""
        }
        isUpdateBIO={
          starDetail.info && !dataItem.owner && !dataItem.market?.seller
            ? true
            : false
        }
        idStar={dataItem.id}
        updateStarDetailWhenUpdateBIO={updateStarDetailWhenUpdateBIO}
      />
      {openViewDetail.value && (
        <Form3dStar
          openForm={openViewDetail.value}
          onClose={onCloseDialog}
          id={starDetail.id}
        />
      )}
    </div>
  );
};

export default ImagePreviewCard;
