import React, { useEffect } from "react";
import { Router, useLocation, Switch } from "react-router-dom";
import { PATH } from "helpers/constant";
import PrivateRoute from "components/Router/PrivateRoute";
import PublicRoute from "components/Router/PublicRoute";
import ForgotPassword from "pages/auth/ForgotPassword";
import ResetPassword from "pages/auth/ResetPassword";
import LoginPage from "pages/auth/LoginPage";
import KeywordsPage from "pages/keywords/KeywordsPage";
import StarPage from "pages/stars/StarPage";
import { history, pushTo } from "helpers/history";
import UsersMobilePage from "pages/users-mobile/UsersMobilePage";
import CMSUsersPage from "pages/cms-users/CMSUsersPage";
import LegalPage from "pages/legal-page/LegalPage";
import ReferralCodePage from "pages/referral-code/ReferralCodePage";
import NotificationPage from "pages/notifications/NotificationPage";
import FeePage from "pages/fee/FeePage";
import SettingPage from "pages/setting/SettingPage";
import EventPage from "pages/event/EventPage";
import ReportPage from "pages/reports/ReportPage";
import { subscribingWallet } from "helpers/blockchain";
import NotFoundPage from "pages/layout/organisms/NotFoundPage";
// import TreasurePage from "pages/treasure/TreasurePage";
// import FlagPage from "pages/flag/FlagPage";
import ManageVersions from "pages/manage-versions";
// import AdvertisementPage from "pages/advertisement/Advertisement";
// import GifPage from "pages/gif/Gif";

const AppRoutes = (): JSX.Element => {
  useEffect(() => {
    subscribingWallet();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const location = useLocation();

  const checkAuth = () => {
    const access = localStorage.getItem("access_token");
    if (access && location.pathname === "/") {
      return pushTo(PATH.stars);
    }
  };

  React.useEffect(() => {
    checkAuth();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Router history={history}>
      <Switch>
        <PrivateRoute exact path={PATH.cmsUsers} component={<CMSUsersPage />} />
        <PrivateRoute exact path={PATH.stars} component={<StarPage />} />
        <PrivateRoute exact path={PATH.keywords} component={<KeywordsPage />} />
        <PrivateRoute
          exact
          path={PATH.mobileUsers}
          component={<UsersMobilePage />}
        />
        <PublicRoute exact path={PATH.login} component={<LoginPage />} />
        <PrivateRoute exact path={PATH.legalPage} component={<LegalPage />} />
        <PrivateRoute
          exact
          path={PATH.referralCode}
          component={<ReferralCodePage />}
        />
        <PrivateRoute
          exact
          path={PATH.notifications}
          component={<NotificationPage />}
        />
        {/* <PrivateRoute
          exact
          path={PATH.manageVersions}
          component={<ManageVersions />}
        /> */}
        <PrivateRoute exact path={PATH.settings} component={<SettingPage />} />
        <PrivateRoute exact path={PATH.events} component={<EventPage />} />
        <PrivateRoute exact path={PATH.reports} component={<ReportPage />} />
        {/* <PrivateRoute exact path={PATH.treasure} component={<TreasurePage />} />
        <PrivateRoute exact path={PATH.flag} component={<FlagPage />} /> */}
        {/* <PrivateRoute
          exact
          path={PATH.advertisement}
          component={<AdvertisementPage />}
        /> */}
        <PrivateRoute exact path={PATH.fee} component={<FeePage />} />
        {/* <PrivateRoute exact path={PATH.gif} component={<GifPage />} /> */}
        <PublicRoute
          exact
          path={PATH.forgotPassword}
          component={<ForgotPassword />}
        />
        <PublicRoute path={PATH.resetPassword} component={<ResetPassword />} />
        <PrivateRoute path="*" component={<NotFoundPage />} />
      </Switch>
    </Router>
  );
};

export default AppRoutes;
