export enum STATUS_RESPONSE {
  SUCCESS = "success",
  ERROR = "error",
}

export enum STATUS_RESPONSE_CODE {
  ERROR = 400,
  SUCCESS = 200,
}
export interface SELECT_PARAM_STAR {
  label: string;
  value: string;
}
export enum ORDER_DIRECTION {
  ASC = "ASC",
  DESC = "DESC",
  NO = "",
}

export enum TYPE_BLOCK_CHAIN {
  INCREASE_ALLOWANCE = "increaseAllowance",
  INCREASE_BALANCE = "Insufficient balance",
}

export enum STATUS_RESPONSE_SMART_CONTRACT {
  ENABLE_METAMASK_SUCCESS = "ENABLE_METAMASK_SUCCESS",
  ENABLE_METAMASK_ERROR = "ENABLE_METAMASK_ERROR",
  UNLOCK_METAMASK = "UNLOCK_METAMASK",
  CONNECT_BNB_SMART_CHAIN_TESTNET_SUCCESS = "CONNECT_BNB_SMART_CHAIN_TESTNET_SUCCESS",
  CONNECT_BNB_SMART_CHAIN_TESTNET_ERROR = "CONNECT_BNB_SMART_CHAIN_TESTNET_ERROR",
  DETECT_CHAIN_ID = "DETECT_CHAIN_ID",
}
