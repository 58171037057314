/* eslint-disable @typescript-eslint/no-explicit-any */
import BackdropCustomize from "components/BackdropCustomize";
import DialogCard from "components/Dialog/DialogCard";
import Typography from "components/Typography";
import { useBoolean, useString } from "helpers/hooks";
import { STATUS_RESPONSE_CODE } from "types";
import { FormSetPriceProps } from "../pages/keywords/types";
import * as XLSX from "xlsx";
import axios from "axios";
import { showNotification } from "helpers/util";

const FormSetPrice = (props: FormSetPriceProps): any => {
  const { openForm, type, onClose, handleUpdateList } = props;
  const isLoading = useBoolean();
  const keyInputFile = useString();

  const submitFileStar = async (data: any) => {
    keyInputFile.setValue(Math.random().toString(36));
    isLoading.setValue(true);

    const body: any = [];

    for (const item of data) {
      const { model, price } = item;
      const name = item.name.toString().toLowerCase();
      try {
        const res: any = await axios.get(
          `/api/system/star/name/${name}/${model}`
        );
        const id: any = res?.data?.data.id;
        const nameStar: any = res?.data?.data.name.toLowerCase();
        if (name === nameStar) {
          // eslint-disable-next-line @typescript-eslint/no-shadow
          const item = { id, price };
          body.push(item);
        }
      } catch (error) {
        console.log("error", error);
      }
    }

    if (body.length > 0) {
      await axios
        .post(`/api/system/star/set-mint-price`, body)
        .then((response: any) => {
          if (response.data.statusCode === STATUS_RESPONSE_CODE.SUCCESS) {
            showNotification("success", "Set price successfully!");
            handleUpdateList(body);
            return;
          }
          showNotification(
            "error",
            response.data.data
              ? response.data.data.errors
              : response.data.message
          );
        })
        .catch(() => {
          console.log("error");
          console.log(STATUS_RESPONSE_CODE.ERROR);
        });
    }
    onClose();
  };

  const submitFileKeyword = async (data: any) => {
    keyInputFile.setValue(Math.random().toString(36));
    isLoading.setValue(true);

    const body: any = [];

    for (const item of data) {
      console.log("here", item.name);
      const name = item.name.toString().toLowerCase().trim();
      const price = item?.price;
      try {
        const res: any = await axios.get(`/api/system-keywords/search/${name}`);
        const id: any = res?.data?.data.id;
        const nameStar: any = res?.data?.data.name.toLowerCase();
        if (name === nameStar) {
          // eslint-disable-next-line @typescript-eslint/no-shadow
          const data = { id, price };
          body.push(data);
        }
      } catch (error) {
        console.log(`error`, error);
      }
    }

    if (body.length > 0) {
      await axios
        .post(`/api/system-keywords/set-mint-price`, body)
        .then((response: any) => {
          if (response.data.statusCode === STATUS_RESPONSE_CODE.SUCCESS) {
            showNotification("success", "Set price successfully!");
            handleUpdateList(body);
            return;
          }
          showNotification(
            "error",
            response.data.data
              ? response.data.data.errors
              : response.data.message
          );
        })
        .catch(() => {
          console.log(STATUS_RESPONSE_CODE.ERROR);
        });
    }
    onClose();
  };

  const fileSelectedHandler = (event: any) => {
    event.preventDefault();
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      const reader = new FileReader();
      reader.readAsArrayBuffer(selectedFile);
      reader.onload = (evt) => {
        const result = evt.target?.result;
        const workBook = XLSX.read(result, { type: "binary" });
        const worksheetName = workBook.SheetNames[0];
        const worksheet = workBook.Sheets[worksheetName];
        const data = XLSX.utils.sheet_to_json(worksheet);

        if (type === "Star") {
          const newData = data.map((item: any) => ({
            price: item.price || item.Price,
            model:
              item.model.trim()?.toLowerCase() == "planet"
                ? "plan"
                : item.model.trim()?.toLowerCase(),
          }));
          submitFileStar(newData);
        } else if (type === "Keyword") {
          data.map((item: any) => {
            if (item.name === false) {
              item.name = "FALSE";
            } else if (item.name === true) {
              item.name = "TRUE";
            }
          });
          console.log({ data });
          const newData = data.map((item: any) => ({
            name: item.name || item.Name,
            price: item.price || item.Price,
          }));
          console.log({ newData });
          submitFileKeyword(newData);
        }
      };
    }
  };

  const handleDownloadFileSample = () => {
    const link = document.createElement("a");
    link.href = type === "Star" ? `/SetPrice.xlsx` : `/SetPriceKeyword.xlsx`;
    link.setAttribute("download", "Sample File");
    document.body.appendChild(link);
    link.click();
    link?.parentNode?.removeChild(link);
  };

  const onDrop = (event) => {
    event.stopPropagation();
    event.preventDefault();
    const { files } = event.dataTransfer;
    if (typeof files !== "object") {
      return;
    }
    const newFiles: any[] = Object.values(files) || [];
    if (newFiles.length) {
      if (type === "Star") {
        submitFileStar(newFiles[0]);
      }
      if (type === "Keyword") {
        submitFileKeyword(newFiles[0]);
      }
    }
  };
  const onDragEnter = (evt) => {
    evt.stopPropagation();
    evt.preventDefault();
  };
  const onDragOver = (evt) => {
    evt.stopPropagation();
    evt.preventDefault();
  };
  const onDragLeave = (evt) => {
    evt.stopPropagation();
    evt.preventDefault();
  };

  return (
    <DialogCard
      openPopup={openForm}
      disablePopup
      handleCLoseDialog={onClose}
      title="Import XLSX file"
    >
      <div
        className="flex flex-col"
        onDrop={onDrop}
        onDragEnter={onDragEnter}
        onDragOver={onDragOver}
        onDragLeave={onDragLeave}
      >
        <div>
          <input
            id="container-files-import-keyword"
            onChange={fileSelectedHandler}
            accept=".csv"
            type="file"
            style={{ display: "none" }}
            key={keyInputFile.value || ""}
          />
          <label htmlFor="container-files-import-keyword">
            <div className="flex items-center justify-center card-import cursor-pointer">
              <Typography textColor="text-black" fontWeight="font-medium">
                Add or drop files
              </Typography>
            </div>
          </label>
        </div>
        <div className="flex items-center mt-4">
          <p
            className="text-xs text-gray-custom italic cursor-pointer"
            onClick={handleDownloadFileSample}
          >
            <span className="text-primary-custom underline mr-1">
              Download the sample file
            </span>
            to see what you can import!
          </p>
        </div>
      </div>

      {isLoading.value ? <BackdropCustomize /> : null}
    </DialogCard>
  );
};
export default FormSetPrice;
