import DialogCard from "components/Dialog/DialogCard";
import React, { FC } from "react";
import Iframe from "react-iframe";
import "../styles/index.css";
import { Form3dStarProps } from "../types";

export const Form3dStar: FC<Form3dStarProps> = ({ openForm, onClose, id }) => {
  const urlWeb = `${process.env.REACT_APP_3D_URL}/?star=` + id;
  return (
    <DialogCard
      openPopup={openForm}
      disablePopup
      handleCLoseDialog={onClose}
      title={"View star 3d"}
      rootStyle={{
        width: "320px",
        height: "720px",
      }}
    >
      <div className="flex justify-center">
        <Iframe
          url={urlWeb}
          width="400px"
          height="600px"
          id="myId"
          className="myClassname"
        />
      </div>
    </DialogCard>
  );
};
