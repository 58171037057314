import BackdropCustomize from "components/BackdropCustomize";
import Table from "components/Table/Table";
import { useBoolean, useString, useTable } from "helpers/hooks";
import { cloneDeep } from "lodash";
import PageLayout from "pages/layout/organisms/PageLayout";
import { useEffect, useState } from "react";
import FormDialog from "./organisms/FormDialog";
import { getRolesMiddleware, getSystemUserMiddleware } from "./services/api";
import { ParamsUserRequest, RoleDetail, UserDetail } from "./types";
import { dataHeaderUser } from "./utils";
import FilterTable from "components/Filter/FilterTable";
import DestroyDialog from "components/Dialog/DestroyDialog";
import Axios, { CancelTokenSource } from "axios";
import { showNotification } from "helpers/util";

const CMSUsersPage = (): JSX.Element => {
  const [systemUsers, setSystemUsers] = useState<UserDetail[]>([]);
  const [roles, setRoles] = useState<RoleDetail[]>([]);
  const openDestroy = useBoolean();
  const idSystemUser = useString();

  const {
    handleChangeInputSearch,
    handleChangePage,
    limit,
    orderBy,
    orderDirection,
    page,
    search,
    searchParamRequest,
    total,
    handleChangeSort,
    isLoadingPage,
    isLoadingTable,
  } = useTable();

  useEffect(() => {
    const source: CancelTokenSource = Axios.CancelToken.source();
    getSystemUser(source);
    return () => source.cancel();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    page.value,
    searchParamRequest.value,
    orderBy.value,
    orderDirection.value,
  ]);

  useEffect(() => {
    getRoles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getSystemUser = async (source?: CancelTokenSource) => {
    try {
      const params: ParamsUserRequest = {
        limit: limit.value,
        page: page.value,
        search: searchParamRequest.value,
      };
      if (orderBy.value) {
        params.orderBy = orderBy.value;
        params.orderDirection = orderDirection.value;
      }

      const dataRes = await getSystemUserMiddleware(params, source);
      total.setValue(dataRes.total);
      setSystemUsers(dataRes.items);
      cleanStateRequest();
    } catch (error) {
      if (!Axios.isCancel(error)) {
        cleanStateRequest();
        showNotification("error", "Server Error");
      }
    }
  };
  const cleanStateRequest = () => {
    isLoadingPage.setValue(false);
    isLoadingTable.setValue(false);
  };

  const getRoles = async () => {
    const dataRes = await getRolesMiddleware();
    setRoles(
      dataRes.map((role) => {
        return {
          ...role,
          value: role.name,
          label: role.name,
        };
      })
    );
  };

  const handleUpdateListAdd = () => {
    isLoadingTable.setValue(true);
    if (page.value !== 1) {
      page.setValue(1);
      return;
    }
    getSystemUser();
  };

  const handleUpdateListWhenUpdateUser = (dataRes: UserDetail) => {
    const newSystemUsers = cloneDeep(systemUsers);
    const index = newSystemUsers.findIndex((el) => el.id === dataRes.id);
    if (index > -1) {
      newSystemUsers[index] = dataRes;
    }
    setSystemUsers(newSystemUsers);
  };
  const handleOpenUpdateList =
    (key: "delete" | "edit", dataRes: UserDetail) => () => {
      openDestroy.setValue(true);
      idSystemUser.setValue(dataRes.id);
    };

  return (
    <PageLayout
      title="CMS Users"
      childrenAction={
        <div className="flex items-center justify-between h-full pr-8">
          <FormDialog roles={roles} handleUpdateList={handleUpdateListAdd} />
          <FilterTable
            search={search.value}
            handleChangeInputSearch={handleChangeInputSearch}
          />
        </div>
      }
    >
      <Table
        limit={limit.value}
        page={page.value}
        countItems={total.value}
        headers={dataHeaderUser(
          roles,
          handleUpdateListWhenUpdateUser,
          handleOpenUpdateList
        )}
        handleChangePage={handleChangePage}
        data={systemUsers.length ? systemUsers : []}
        handleChangeSort={handleChangeSort}
        orderBy={orderBy.value}
        orderDirection={orderDirection.value}
        isLoadingTable={isLoadingTable.value}
      />

      {isLoadingPage.value ? <BackdropCustomize /> : null}
      {openDestroy.value ? (
        <DestroyDialog
          url={`/api/system-user/${idSystemUser.value}`}
          label="Destroy user"
          message="Destroy user successfully!"
          handleUpdateWhenDestroy={handleUpdateListAdd}
          onClose={() => openDestroy.setValue(false)}
          openPopup={openDestroy.value}
        />
      ) : null}
    </PageLayout>
  );
};

export default CMSUsersPage;
