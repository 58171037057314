import Axios, { AxiosResponse, CancelTokenSource } from "axios";
import { showNotification } from "helpers/util";
import { STATUS_RESPONSE_CODE } from "types";
import {
  CreateUserRequest,
  ParamsUserRequest,
  RoleDetail,
  UpdateUserRequest,
  UserDetail,
} from "../types";
// eslint-disable-next-line
export const getSystemUserMiddleware = async (
  params: ParamsUserRequest,
  source?: CancelTokenSource
) => {
  const response: AxiosResponse<{
    data: {
      items: UserDetail[];
      total: number;
    };
  }> = await Axios.get(`/api/system-user`, {
    params,
    cancelToken: source && source.token,
  });
  return response.data.data;
};
// eslint-disable-next-line
export const getRolesMiddleware = async () => {
  const response: AxiosResponse<{
    data: RoleDetail[];
  }> = await Axios.get(`/api/role`);
  return response.data.data;
};
// eslint-disable-next-line
export const addUserMiddleware = (
  request: CreateUserRequest,
  callBack: (status: STATUS_RESPONSE_CODE, dataRes?: UserDetail) => void
) => {
  Axios.post(`/api/system-user`, request)
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    .then((response: any) => {
      if (response.data.statusCode === STATUS_RESPONSE_CODE.SUCCESS) {
        showNotification("success", "Create new user successfully!");
        callBack(response.data.statusCode, response.data.data);
        return;
      }
      showNotification(
        "error",
        response.data.data ? response.data.data.errors : response.data.message
      );

      callBack(response.data.statusCode);
    })
    .catch(() => {
      callBack(STATUS_RESPONSE_CODE.ERROR);
    });
};
// eslint-disable-next-line
export const updateUserMiddleware = (
  idUser: string,
  request: UpdateUserRequest,
  callBack: (status: STATUS_RESPONSE_CODE, dataRes?: UserDetail) => void
) => {
  Axios.patch(`/api/system-user/${idUser}`, request)
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    .then((response: any) => {
      if (response.data.statusCode === STATUS_RESPONSE_CODE.SUCCESS) {
        showNotification("success", "Update user successfully!");
        callBack(response.data.statusCode, response.data.data);
        return;
      }
      showNotification(
        "error",
        response.data.data ? response.data.data.errors : response.data.message
      );

      callBack(response.data.statusCode);
    })
    .catch(() => {
      callBack(STATUS_RESPONSE_CODE.ERROR);
    });
};
